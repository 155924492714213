import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = () => {
  return (
    <Layout>
      <SEO
        title="Linha do tempo da História Islâmica"
        description="A seguir preparamos a Linha do tempo da História Islâmica para facilitar a leitura e entendimento da história, entendendo os acontecimentos no mundo..."
        url={`${process.env.GATSBY_HOST_URL}/linha-do-tempo-da-historia-islamica`}
      />

      <div
        class="content-grid page-grid politica-de-privacidade"
        style={{ maxWidth: "1100px", margin: "0 auto" }}
      >
        <div class="content content-custom">
          <h1 itemprop="name" itemtype="http://schema.org/Thing">
            Linha do tempo da História Islâmica
          </h1>
          <div>
            <p>
              A seguir preparamos&nbsp;a Linha do tempo da História Islâmica
              para facilitar a leitura e entendimento da história, entendendo os
              acontecimentos no mundo Islâmico passo a passo.
            </p>

            <ul>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Como sabemos que o Alcorão nunca foi modificado?"
                    href="https://iqaraislam.com/como-sabemos-que-o-alcorao-nunca-foi-modificado/"
                  >
                    609 &#8211; Primeiros versos do Alcorão foram revelados ao
                    Profeta Muhammad
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a href="https://iqaraislam.com/como-o-isla-se-disseminou-na-india/">
                    629 &#8211; A primeira mesquita da Índia é construída em
                    Kerala
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Islam realmente se espalhou pela Espada?"
                    href="https://iqaraislam.com/o-islam-realmente-se-espalhou-pela-espada/"
                  >
                    630 &#8211; Islam se expande para Síria, Pérsia, sem nenhuma
                    conversão forçada
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Jerusalém e Umar Ibn Al-Khatab"
                    href="https://iqaraislam.com/jerusalem-e-umar-ibn-al-khatab/"
                  >
                    637 &#8211; Jerusalém é conquistada pelos Muçulmanos, que
                    preservam os direitos religiosos dos Cristãos
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="A Mesquita Al-Aqsa durante as eras"
                    href="https://iqaraislam.com/a-mesquita-al-aqsa-durante-as-eras/"
                  >
                    637 &#8211; Umar ibn Al-Khattab constrói a mesquita Al-Aqsa
                    em Jerusalém
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a href="https://iqaraislam.com/carijitas-quem-sao-e-qual-sua-origem/">
                    661 &#8211; Os Carijitas assassinaram ´Ali em Kufa
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Os Quatro Imames do Fiqh"
                    href="https://iqaraislam.com/os-quatro-imames-do-fiqh/"
                  >
                    699 &#8211; 855 &#8211; As vidas dos Imames Abu Hanifa,
                    Malik, Shafi e Ahmad
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Cristianismo e a Conquista Islâmica da Espanha"
                    href="https://iqaraislam.com/cristianismo-e-a-conquista-islamica-da-espanha/"
                  >
                    711 &#8211; Tariq ibn Ziyad lidera seus exércitos para
                    dentro da Peninsula Ibéria
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Ícone Cultural de Andaluzia"
                    href="https://iqaraislam.com/o-icone-cultural-de-andaluzia/"
                  >
                    800 &#8211; Ziryab se torna um Ícone Cultural em toda a
                    Península Ibérica
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Al-Khawarizmi e a Matemática Moderna"
                    href="https://iqaraislam.com/al-khawarizmi-e-a-matematica-moderna/"
                  >
                    832 &#8211; A Casa da Sabedoria é estabelecida, com
                    al-Khawarizmi estudando matemática nela
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Imam Al Bukhari e a Ciência dos Hadiths"
                    href="https://iqaraislam.com/imam-al-bukhari-e-a-ciencia-dos-hadiths/"
                  >
                    846 &#8211; Imam al-Bukhari compila Sahih al-Bukhari
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Educação na História Islâmica"
                    href="https://iqaraislam.com/educacao-na-historia-islamica/"
                  >
                    859 &#8211; Fatima al-Fihri funda a primeira Universidade
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Al-Zahrawi – O Pioneiro da Cirurgia Moderna"
                    href="https://iqaraislam.com/al-zahrawi-o-pioneiro-da-cirurgia-moderna/"
                  >
                    900 &#8211; Al-Zahrawi revoluciona a cirurgia
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Ibn Al-Haytham – O Primeiro Cientista"
                    href="https://iqaraislam.com/ibn-al-haytham-o-primeiro-cientista/"
                  >
                    1021 &#8211; Ibn al-Haytham escreve O Livro da Ótica
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Desunião em Andaluzia: O Período das Taifas"
                    href="https://iqaraislam.com/desuniao-em-andaluzia-o-periodo-das-taifas/"
                  >
                    1031 – O Califado de Córdoba cai
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Al-Ghazali e a renascença das Ciências Islâmicas"
                    href="https://iqaraislam.com/al-ghazali-e-a-renascenca-das-ciencias-islamicas/"
                  >
                    1095 &#8211; Imam al-Ghazali escreve o livro A Incoerência
                    dos Filósofos
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="As Cruzadas Parte 1: A Invasão"
                    href="https://iqaraislam.com/as-cruzadas-parte-1-a-invasao/"
                  >
                    1187 &#8211; Salah al-Din libera Jerusalém
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="A Invasão Mongol e a destruição de Bagdá"
                    href="https://iqaraislam.com/a-invasao-mongol-e-a-destruicao-de-bagda/"
                  >
                    1258 &#8211; Os Mongóis destroem Bagdá
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O nascimento do Império Otomano"
                    href="https://iqaraislam.com/o-nascimento-do-imperio-otomano/"
                  >
                    1299 &#8211; Império Otomano é fundado
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Uma Mina de Ouro no Deserto – História do Mali"
                    href="https://iqaraislam.com/uma-mina-de-ouro-no-deserto-historia-do-mali/"
                  >
                    1324 &#8211; Mansa Musa vai ao Hajj
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Caligrafia Otomana Tughra"
                    href="https://iqaraislam.com/caligrafia-otomana-tughra/"
                  >
                    1326 &#8211; Sultão Otomano Orhan começa a utilizar Tughras
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Como ganhar Dinheiro – Ibn Khaldun e os Impostos"
                    href="https://iqaraislam.com/como-ganhar-dinheiro-ibn-khaldun-e-os-impostos/"
                  >
                    1377 &#8211; Ibn Khaldun escreve o Muqaddimah
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Sultonato de Malaca"
                    href="https://iqaraislam.com/o-sultonato-de-malaca/"
                  >
                    1400 &#8211; Sultonato de Malaca é fundado
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Maior Explorador da China – Zeng He"
                    href="https://iqaraislam.com/o-maior-explorador-da-china-zeng-he/"
                  >
                    1405 &#8211; Zheng He lidera sua primeira expedição
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Mehmed II e a promessa do Profeta Muhammad"
                    href="https://iqaraislam.com/mehmed-ii-e-a-promessa-do-profeta-muhammad/"
                  >
                    1453 &#8211; Otomanos conquistam Constantinopla
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Direito dos não-muçulmanos no Império Otomano"
                    href="https://iqaraislam.com/direito-dos-nao-muculmanos-no-imperio-otomano/"
                  >
                    1453 &#8211; Mehmed II instituio o Sistema Millet no Império
                    Otomano
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Granada – O Último Reino Espanhol Islâmico"
                    href="https://iqaraislam.com/granada-o-ultimo-reino-espanhol-islamico/"
                  >
                    1492 &#8211; O Último Reinado Islâmico em Andaluzia cai
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Colombo não foi o primeiro a cruzar o Atlântico"
                    href="https://iqaraislam.com/colombo-nao-foi-o-primeiro-a-cruzar-o-atlantico/"
                  >
                    1492 &#8211; Colombo navega para América centenas de anos
                    depois dos muçulmanos
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Reinado do Sultão Suleyman Kanuni"
                    href="https://iqaraislam.com/o-reinado-do-sultao-suleyman-kanuni/"
                  >
                    1520 &#8211; Suleyman Kanuni se torna o Sultão do Império
                    Otomano
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O maior Arquiteto de todos os tempos"
                    href="https://iqaraislam.com/o-maior-arquiteto-de-todos-os-tempos/"
                  >
                    1574 &#8211; Mimar Sinan constrói a Mesquita Selimiye em
                    Edirne
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Esquecidos da Espanha – Expulsão dos Mouriscos"
                    href="https://iqaraislam.com/muculmanos-esquecidos-da-espanha-expulsao-dos-mouriscos/"
                  >
                    1609 &#8211; Expulsão Final dos muçulmanos da Espanha
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Pioneiros da Aviação – Vôo no Século 17"
                    href="https://iqaraislam.com/pioneiros-da-aviacao-voo-no-seculo-17/"
                  >
                    1630 &#8211; Hezarafen Ahmed Çelebi completa o Primeiro Vôo
                    Intercontinental
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Aurangzeb e o reinado Islâmico na Índia"
                    href="https://iqaraislam.com/aurangzeb-e-o-reinado-islamico-na-india/"
                  >
                    1659 &#8211; O Reinado de Aurangzeb começa na Índia
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Declínio do Império Otomano – Parte 1: Políticas e Economia"
                    href="https://iqaraislam.com/declinio-do-imperio-otomano-parte-1-politicas-e-economia/"
                  >
                    1700 &#8211; 1.800 &#8211; O Declínio do Império Otomano
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Como os muçulmanos ajudaram a Independência Americana"
                    href="https://iqaraislam.com/como-os-muculmanos-ajudaram-a-independencia-americana/"
                  >
                    1776 &#8211; Os Muçulmanos ajudaram a causar a Independência
                    Americana
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Primeiro Muçulmano Estudioso Americano: Bilali Muhammad"
                    href="https://iqaraislam.com/o-primeiro-muculmano-estudioso-americano-bilali-muhammad/"
                  >
                    1802 &#8211; Bilali Muhammad chega na ilha Sapelo, Georgia,
                    EUA
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="A Revolta dos Escravos Muçulmanos da Bahia"
                    href="https://iqaraislam.com/a-revolta-dos-escravos-muculmanos-da-bahia/"
                  >
                    1835 &#8211; Revolta dos Escravos Muçulmanos no Brasil
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="A Primeira Guerra Anglo-Afegã"
                    href="https://iqaraislam.com/a-primeira-guerra-anglo-afega/"
                  >
                    1842 &#8211; Afeganistão derrota a ocupação Britânica
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Quem inventou o Líbano?"
                    href="https://iqaraislam.com/quem-inventou-o-libano/"
                  >
                    1861 &#8211; França dita a criação do Líbano moderno
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="O Último Grande Califa: Abdulhamid II"
                    href="https://iqaraislam.com/o-ultimo-grande-califa-abdulhamid-ii/"
                  >
                    1876 &#8211; Abdulhamid II se tonar o Sultão/Califa do
                    Império Otomano
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="Como os Ingleses dividiram o Mundo Árabe"
                    href="https://iqaraislam.com/como-os-ingleses-dividiram-o-mundo-arabe/"
                  >
                    1910 &#8211; Inglaterra divide o Oriente Médio Árabe
                  </a>
                </span>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a
                    style={{ color: "#0000ff;" }}
                    title="A Revolta Árabe da Primeira Guerra Mundial"
                    href="https://iqaraislam.com/a-revolta-arabe-da-primeira-guerra-mundial/"
                  >
                    1916 &#8211; A Revolta Árabe se inicia
                  </a>
                </span>
              </li>
              <li>
                <a
                  title="A Raiz da Divisão Sectária do Iraque"
                  href="https://iqaraislam.com/a-raiz-da-divisao-sectaria-do-iraque/"
                >
                  <span style={{ color: "#0000ff;" }}>
                    1920 &#8211; Inglaterra desenha as fronteiras do Iraque
                    moderno, criando divisões sectárias
                  </span>
                </a>
              </li>
              <li>
                <a
                  title="Como Ataturk criou a Turquia secular"
                  href="https://iqaraislam.com/como-ataturk-criou-a-turquia-secular/"
                >
                  <span style={{ color: "#0000ff;" }}>
                    1923 &#8211; Mustafa Kamal Ataturk estabelece a República
                    Secular da Turquia
                  </span>
                </a>
              </li>
              <li>
                <span style={{ color: "#0000ff;" }}>
                  <a href="https://iqaraislam.com/nakba-catastrofe-palestina-de-1948/">
                    1948 &#8211; Mais de 700.000 Palestino se tornam refugiados
                    em Nakba
                  </a>
                </span>
              </li>
              <li>
                <a
                  rel="noreferrer noopener"
                  aria-label="1979 - A Revolução Iraniana muda o destino do Irã (abre numa nova aba)"
                  href="https://iqaraislam.com/o-aiatola-khomeini-e-revolucao-iraniana-de-1979/"
                  target="_blank"
                >
                  1979 &#8211; A Revolução Iraniana muda o destino do Irã
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
